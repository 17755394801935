import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { logoutAction } from '../../../pages/Login/redux/action';
import { logoutHandler } from '../../../pages/Login/actionMethods';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from 'components/loader/Loader';

const ProfileDropdown = props => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const handleLogout = async e => {
    e.preventDefault();
    try {
      setloading(true);
      const data = await logoutHandler(props.token);
      if (data.data.status === 1) {
        props.logoutAction();
        setloading(false);
        navigate('/login');
      }
    } catch (err) {
      setloading(false);
      toast.error(err.response.data.message);
    }
  };
  return (
    <>
      <Dropdown navbar={true}>
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          <Avatar
            size="xl"
            name={(props.name && props.name.charAt(0)) || 'USER'}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item as={Link} to="/profile">
              Profile
            </Dropdown.Item>
            {/* <Dropdown.Item as={Link} to="/change-password">
              Change Password
            </Dropdown.Item> */}
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {loading && <Loader loading={loading} />}
    </>
  );
};
const actionCreators = {
  logoutAction: logoutAction
};
const mapStateToProps = state => ({
  token: state.auth && state.auth.token,
  name:
    state.auth && state.auth.authentication && state.auth.authentication.name
});

export default connect(mapStateToProps, actionCreators)(ProfileDropdown);
