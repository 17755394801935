import React, { useState, useEffect } from 'react';
import MyCard from '_components/Card/CoursesCountCard';
import { Row, Col, Button, ProgressBar } from 'react-bootstrap';
import AdviceCollapse from './Advice_collapse';
import Loader from 'components/loader/Loader';
import { useParams, useNavigate } from 'react-router-dom';
import { getpastQuizDetails } from './actionMethods';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
const Result = props => {
  const { id } = useParams();

  const [loading, setloading] = useState(false);
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDetails() {
      setloading(true);
      try {
        const data = await getpastQuizDetails(id, props.token);
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.message);
          } else {
            const response = data.data.data;
            toast.success(data.data.message);
            setResult(response);
            setloading(false);
          }
        }
      } catch (err) {
        toast.error(err.response.data.message);
        setloading(false);
      }
    }
    if (id) {
      fetchDetails();
    } else {
      setResult(props.result);
    }
  }, [id]);

  return (
    <>
      <Row className="my-4 mx-1 p-3 bg-light">
        <h3 className="text-start fs-2 p-0">
          Results : {result && result.quiz_date}
        </h3>
        <h5 className="p-0">
          Total Score :{' '}
          <span className="fs-1 text-primary">
            {result && result.total_score}
          </span>{' '}
        </h5>
        <ProgressBar
          className="p-0 my-3"
          now={result && parseInt(result.total_score)}
          style={{ width: '100%', height: 10 }}
        />

        {/* <Col className="mt-3" sm={4}>
          <MyCard title="Total Score" result={result.total_score} />
        </Col> */}
      </Row>
      <div className="bg-light p-3 my-4">
        <h5>Spheres of Happiness</h5>
        <span className="fs--1">
          From the answers you have given, we have broken down your general
          happiness score into 4 spheres of happiness. Here you can see which
          areas of your life are the strongest and which are most in need of
          attention.
        </span>
        <Row sm={1} md={2} lg={4} className="my-2">
          {result &&
            result.health_categories.length &&
            result.health_categories.map((item, index) => {
              return (
                <Col key={index} className="mt-3" sm={3}>
                  <MyCard title={item.title} result={item.score} />
                </Col>
              );
            })}
        </Row>
      </div>
      <div className="bg-light p-3 my-4">
        <div className="my-3">
          <h5>Advice Categories</h5>
          <span className="fs--1">
            Your tips for the month ahead are in the following categories. All
            the tips are based on your answers to this month's quiz. Follow the
            tips and come back next month to see if your happiness has improved.
          </span>
        </div>
        <div className="subheader bg-grey p-2">
          <h6 className="m-0">Category List</h6>
        </div>
        <Row className="d-inline">
          {result &&
            result.advice_categories.length &&
            result.advice_categories.map((item, index) => {
              return (
                <Col key={index} className="mt-2" sm={12}>
                  <AdviceCollapse title={item.name} advice={item.advice_text} />
                </Col>
              );
            })}
        </Row>
      </div>

      <Row>
        <Col className="m-auto text-center">
          <Button onClick={() => navigate('/dashboard')}>
            Back to Dashboard
          </Button>
        </Col>
      </Row>
      {loading && <Loader loading={loading} />}
    </>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps, null)(Result);
