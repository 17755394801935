import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../pages/Login/redux/reducer';
// import {
//   allVisitorReducer,
//   allStaticDataReducer,
// } from "../pages/Dashboard/redux/reducer";
// import {
//   headerNameReducer,
//   sidebaropenHandlerreducer,
// } from "../components/layouts/redux/reducer";
// import staffActiveReducer from "../pages/StaffProfile/redux/reducer";
// import printReducer from "../pages/AllStaffList/redux/reducer";

const reducers = combineReducers({
  auth: authReducer
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
    localStorage.clear();
  }
  return reducers(state, action);
};

export default persistReducer(persistConfig, rootReducer);
