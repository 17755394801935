import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import BestSellingProducts from './BestSellingProducts';
import { useNavigate } from 'react-router-dom';
import { fetchdashboard } from './actionMethods';
import { toast } from 'react-toastify';
import Loader from 'components/loader/Loader';
import { connect } from 'react-redux';

const Dashboard = props => {
  const [loading, setloading] = useState(false);
  const [is_quiz_available, setis_quiz_available] = useState(true);
  const [next_available_date, setnext_available_date] = useState('');
  const [qhistory, setqhistory] = useState([]);
  useEffect(() => {
    async function fetchRecords() {
      setloading(true);
      try {
        const data = await fetchdashboard(props.token);
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.message);
          } else {
            const response = data.data.data;
            setis_quiz_available(response.is_quiz_available);
            setqhistory(response.quiz_history);
            setnext_available_date(response.quiz_available_days);
          }
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
      setloading(false);
    }

    fetchRecords();
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <Row className="d-inline g-3 m-auto mb-3">
        <Col className="text-center" md={12}>
          <Card className="mt-4">
            <Card.Body className="py-4">
              <Row>
                {is_quiz_available ? (
                  <>
                    <Col className="fs-1 py-1 text-center text-md-start">
                      Are you ready to take the quiz ?{' '}
                      <Button
                        onClick={() => {
                          navigate('/quiz');
                        }}
                        className="fs-1"
                      >
                        Start Quiz
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Col className="fs-1 text-start">
                    You will be able to take your next quiz in{' '}
                    {next_available_date} days. We’ll send you an email when
                    it’s time to repeat the quiz.
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-5">
          <h4>Past Quiz List</h4>
          {qhistory && qhistory.length ? (
            <BestSellingProducts products={qhistory} />
          ) : (
            <div className="text-center mt-5">
              <h2>No quiz history found.</h2>
            </div>
          )}
        </Col>
      </Row>
      {loading && <Loader loading={loading} />}
    </>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps, null)(Dashboard);
