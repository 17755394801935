import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import mh from 'assets/img/illustrations/mentalhealth.svg';
import ph from 'assets/img/illustrations/physicalhealth.svg';
import sh from 'assets/img/illustrations/socialhealth.svg';
import pnh from 'assets/img/illustrations/professionalhealth.svg';

const MyCard = ({ title, result }) => {
  const [heading] = useState(title);
  const [currentImage, setcurrentImage] = useState('');
  const [currentbgColor, setCurrentBgColor] = useState('');
  useEffect(() => {
    if (title === 'Professional Happiness') {
      setcurrentImage(pnh);
      setCurrentBgColor('#FFF7E2');
    } else if (title === 'Social Happiness') {
      setcurrentImage(sh);
      setCurrentBgColor('#FFEBEB');
    } else if (title === 'Physical Happiness') {
      setcurrentImage(ph);
      setCurrentBgColor('#DBF4FF');
    } else if (title === 'Mental Happiness') {
      setcurrentImage(mh);
      setCurrentBgColor('#EDEAFF');
    }
  }, []);
  return (
    <Card
      style={{ backgroundColor: currentbgColor }}
      className={`overflow-hidden`}
    >
      <Card.Body className="position-relative">
        <Row className="align-items-center">
          <Col xs={3}>
            <img src={currentImage} alt={heading} />
          </Col>
          <Col xs={9} className="px-0">
            <span className="h6">{heading}</span>

            <h2
              className={`fs-2 ${'text-normal'}
          `}
            >
              {result}
            </h2>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MyCard;
