import React, { useState, useEffect } from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/14.png';
import Flex from 'components/common/Flex';
import { Button, Form } from 'react-bootstrap';
import Loader from 'components/loader/Loader';
import { forgotpasswordHandler } from './actionMethods';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      setEmail('');
    };
  }, []);
  const forgotpasswordHandlerLocal = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await forgotpasswordHandler(email);
      if (data && data.status >= 200 && data.status <= 299) {
        if (data.data.status === 1) {
          toast.success(data.data.message);
          navigate(`/check-email?email=${email}`);
        } else {
          toast.error(data.data.message);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
    setEmail('');
    setLoading(false);
  };

  const handleFieldChange = e => {
    setEmail(e.target.value);
  };

  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Forgot your Password?</h3>
      </Flex>
      <span>Enter your email and we'll send you a reset link</span>
      <Form className="mt-2" onSubmit={forgotpasswordHandlerLocal}>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            placeholder="Email address"
            value={email}
            name="email"
            onChange={handleFieldChange}
            type="email"
          />
        </Form.Group>
        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!email}
          >
            Send Reset Link
          </Button>
        </Form.Group>
      </Form>
      <p className="mb-0 fs--1 mt-3 text-center ">
        <Link to="/login">Back to Login</Link>
      </p>
      {loading && <Loader loading={loading} />}
    </AuthSplitLayout>
  );
};

export default ForgotPassword;
