import React, { useState, useEffect } from 'react';
import Loader from 'components/loader/Loader';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { registrationMeta, registrationHandler } from './actionMethods';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ hasLabel }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: '',
      nickname: '',
      email: '',
      password: '',
      confirmPassword: '',
      city: '',
      region: '',
      birthyear: '',
      sin_country: '',
      sin_zodiac_sign: ''
    }
  });

  const [allzodiac_sign, setallzodiac_sign] = useState([]);
  const [allcountry, setallcountry] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch Country and Zodiac sign
    async function fetchData() {
      setloading(true);
      try {
        const data = await registrationMeta();
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.messsage);
          } else {
            setallcountry(data.data.data.country);
            setallzodiac_sign(data.data.data.zodiac_sign);
          }
        }
      } catch (error) {
        toast.error();
      }
      setloading(false);
    }
    fetchData();
    return () => {
      setallcountry([]);
      setallzodiac_sign([]);
    };
  }, []);

  // Handler
  const submitRegistration = async data => {
    let formData = {
      name: data.name,
      email: data.email,
      nickname: data.nickname,
      password: data.password,
      password_confirmation: data.confirmPassword,
      birth_year: data.birthyear,
      zodiac_id: data.sin_zodiac_sign,
      city: data.city,
      region: data.region,
      country_id: data.sin_country
    };

    setloading(true);
    try {
      const data = await registrationHandler(formData);
      if (data && data.status >= 200 && data.status <= 299) {
        if (data.data.status === 0) {
          toast.error(data.data.message.toString());
          setloading(false);
        } else {
          setloading(false);
          toast.success(data.data.message);
          navigate('/login');
        }
      }
    } catch (error) {
      setloading(false);
      if (error.response) {
        toast.error(error.response.data.message.toString());
      } else {
        toast.error(error.message);
      }
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit(submitRegistration)}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            isInvalid={errors.name}
            placeholder={!hasLabel ? 'Name' : ''}
            name="name"
            {...register('name', {
              required: 'Name is required'
            })}
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {errors.name && errors.name.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            <Form.Label>Nick Name</Form.Label>
            <Form.Control
              isInvalid={errors.nickname}
              placeholder={!hasLabel ? 'Nick Name' : ''}
              name="nickname"
              {...register('nickname', {
                required: 'Nick Name is required'
              })}
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {errors.nickname && errors.nickname.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Birth Year</Form.Label>
            <Form.Control
              isInvalid={errors.birthyear}
              placeholder={!hasLabel ? 'Birth Year' : ''}
              {...register('birthyear', {
                required: 'Birth Year is required',
                maxLength: {
                  value: 4,
                  message: 'Birth Year should be 4 digits only'
                }
              })}
              name="birthyear"
              type="number"
            />
            <Form.Control.Feedback type="invalid">
              {errors.birthyear && errors.birthyear.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            isInvalid={errors.email}
            placeholder={!hasLabel ? 'Email address' : ''}
            {...register('email', {
              required: 'Email is required'
            })}
            name="email"
            type="email"
          />
          <Form.Control.Feedback type="invalid">
            {errors.email && errors.email.message}
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              autoComplete="off"
              isInvalid={errors.password}
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'The password must be at least 8 characters.'
                }
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password && errors.password.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              autoComplete="off"
              isInvalid={!!errors.confirmPassword}
              {...register('confirmPassword', {
                required: 'confirm password is required',
                validate: value =>
                  value === watch('password') || 'The password do not match'
              })}
            />

            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && errors.confirmPassword.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            <Form.Label>City</Form.Label>
            <Form.Control
              isInvalid={errors.city}
              {...register('city', {
                required: 'City is required'
              })}
              name="city"
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {errors.city && errors.city.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Region</Form.Label>
            <Form.Control
              isInvalid={errors.region}
              {...register('region', {
                required: 'Region is required'
              })}
              name="region"
              type="text"
            />
            <Form.Control.Feedback type="invalid">
              {errors.region && errors.region.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            <Form.Label>Country</Form.Label>
            <Form.Select
              isInvalid={errors.sin_country}
              {...register('sin_country', {
                required: 'Country is required'
              })}
            >
              <option disabled value="">
                Countries
              </option>
              {allcountry.length &&
                allcountry.map(c => {
                  return (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.sin_country && errors.sin_country.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} sm={6}>
            <Form.Label>Zodiac Sign</Form.Label>
            <Form.Select
              isInvalid={errors.sin_zodiac_sign}
              {...register('sin_zodiac_sign', {
                required: 'Zodiac Sign is required'
              })}
            >
              <option disabled value="">
                Zodiac Sign
              </option>
              {allzodiac_sign.length &&
                allzodiac_sign.map(z => {
                  return (
                    <option key={z.id} value={z.id}>
                      {z.name}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.sin_zodiac_sign && errors.sin_zodiac_sign.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Form.Group className="mb-4">
          <Button className="w-100" type="submit">
            Register
          </Button>
        </Form.Group>
      </Form>
      {loading && <Loader loading={loading} />}
    </>
  );
};
export default RegistrationForm;
