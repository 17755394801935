const authInitialState = {
  isAuthenticated: false,
  token: null
};

export default function authReducer(state = authInitialState, action) {
  switch (action.type) {
    case 'USER_AUTH':
      return action.payload;
    default:
      return state;
  }
}
