import axios from '../../../helpers/axios';

// ----------------------------------------
// get demographicdata handler
// ----------------------------------------
export const demographicdata = token => {
  return axios.get('/demographics', {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

// ----------------------------------------
// get userprofile handler
// ----------------------------------------
export const getuserprofile = token => {
  return axios.get('/profile', {
    headers: { Authorization: 'Bearer ' + token }
  });
};

// ----------------------------------------
// post userprofile handler
// ----------------------------------------
export const updateuserprofileHandler = (formData, token) => {
  return axios.post(
    'profile',
    { ...formData },
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  );
};
