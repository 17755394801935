import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

// import { Navbar } from 'react-bootstrap';
import Logo from 'components/common/Logo';

import { topNavbarBreakpoint } from 'config';
import TopNavRightSideNavItem from 'components/navbar/top/TopNavRightSideNavItem';
import { connect } from 'react-redux';

const MyNavbar = () => {
  const [showDropShadow, setShowDropShadow] = useState(false);
  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

  return (
    <div
      className={classNames(
        'container d-flex mx-0 align-items-center navbar-glass  fs--1 navbar-top ',
        {
          'navbar-glass-shadow': showDropShadow
        }
      )}
      expand={topNavbarBreakpoint}
    >
      <Logo
        logo="../../assets/img/logo.png"
        at="navbar-top"
        width={175}
        id="topLogo"
      />
      <TopNavRightSideNavItem />
    </div>
  );
};
const mapStateToProps = state => ({
  auth: state.auth.token
});
export default connect(mapStateToProps, null)(MyNavbar);
