export const authentication = (authentication, token) => {
  return {
    type: 'USER_AUTH',
    payload: { authentication, token }
  };
};

export const logoutAction = () => {
  return {
    type: 'USER_LOGOUT'
  };
};
