import axios from '../../../helpers/axios';

// ----------------------------------------
// get zodiac sign and country
// ----------------------------------------
export const registrationMeta = () => {
  return axios.get('/registration-meta');
};

// ----------------------------------------
// registration handler
// ----------------------------------------
export const registrationHandler = formData => {
  return axios.post('/register', { ...formData });
};

// ----------------------------------------
// login handler
// ----------------------------------------
export const loginHandler = formData => {
  return axios.post('/login', {
    email: formData.email,
    password: formData.password
  });
};

// ----------------------------------------
// TODO:forgot-password handler(Email)
// ----------------------------------------
export const forgotpasswordHandler = email => {
  return axios.post('/forgot-password', {
    email: email
  });
};

// ----------------------------------------
// TODO:verify Email Url handler
// ----------------------------------------
export const verifyEmailAPI = token => {
  return axios.get(`/user/verify/${token}`);
};

// ----------------------------------------
// PasswordReset handler
// ----------------------------------------
export const resetpasswordHandlerAPI = (data, fptoken, email) => {
  return axios.post('/reset-password', {
    token: fptoken,
    password: data.password,
    password_confirmation: data.confirmPassword,
    email: email
  });
};

// ----------------------------------------
// logout handler
// ----------------------------------------
export const logoutHandler = token => {
  return axios.post(
    `/logout`,
    {},
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  );
};
