import React from 'react';
import { Card, Table, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';

const getTotalPrice = items =>
  items
    .map(({ unit, price }) => unit * price)
    .reduce((total, currentValue) => total + currentValue, 0);

const BestSellingProduct = ({ product }) => {
  const navigate = useNavigate();
  const { quiz_date, score, uuid } = product;
  return (
    <tr
      onClick={() => navigate(`/result/${uuid}`)}
      className="border-bottom border-200 cursor-pointer"
    >
      <td className="align-middle fw-semi-bold">{quiz_date}</td>
      <td className="align-middle pe-card">
        <Flex alignItems="center">
          <ProgressBar now={score} style={{ width: '80px', height: 5 }} />
          <div className="fw-semi-bold ms-3">{score}%</div>
        </Flex>
      </td>
    </tr>
  );
};

const BestSellingProducts = ({ products }) => {
  const totalPrice = getTotalPrice(products);
  const noOfProducts = products.length;

  return (
    <Card className="h-lg-100">
      <Card.Body className="p-0">
        <Table borderless responsive className="mb-0 fs--1">
          <thead className="bg-light">
            <tr className="text-900">
              <th>Date</th>
              <th className="pe-card text-end" style={{ width: '8rem' }}>
                Percentage (%)
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <BestSellingProduct
                product={product}
                totalPrice={totalPrice}
                isLast={index === noOfProducts - 1}
                key={product.id}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
      {/* <Card.Footer className="bg-light py-2">
        <Row className="flex-between-center">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 7 days</option>
              <option>Last Month</option>
              <option>Last Year</option>
            </Form.Select>
          </Col>
          <Col xs="auto">
            <Button variant="falcon-default" size="sm" as={Link} to="#!">
              View All
            </Button>
          </Col>
        </Row>
      </Card.Footer> */}
    </Card>
  );
};

export default BestSellingProducts;
