import React, { useState, useEffect } from 'react';
import { Form, Card, Row, Col, Button, FormCheck } from 'react-bootstrap';
import { getQuizQuestionsHandler, submitQuizHandler } from './actionMethods';
import Loader from 'components/loader/Loader';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import res from './response.json';
import Result from './Result';

const Quiz = props => {
  // getuserprofile
  const [loading, setloading] = useState(false);
  const [questions, setquestions] = useState([]);
  const [quenumber, setquenumber] = useState(0);
  const [result, setResult] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    // setResult(res.data);

    async function fetchQuizQues() {
      setloading(true);
      try {
        const data = await getQuizQuestionsHandler(props.token);
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.message);
          } else {
            const response = data.data.data;
            setquestions(response);
          }
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
      setloading(false);
    }
    fetchQuizQues();
  }, []);
  const nextbtnHandler = e => {
    e.preventDefault();
    e.persist();
    window.scrollTo(0, 0);
    let curcount = quenumber;
    curcount = curcount + 1;
    setquenumber(curcount);
  };

  const prevbtnHandler = e => {
    e.preventDefault();
    e.persist();
    window.scrollTo(0, 0);
    let curcount = quenumber;
    curcount = curcount - 1;
    setquenumber(curcount);
  };
  const changeAnswerHandler = e => {
    e.persist();
    let newArray = [...questions];
    newArray[quenumber].option_id = e.target.value;
    setquestions(newArray);
  };

  const submitbtnHandler = async e => {
    e.preventDefault();
    setloading(true);
    let tempques = questions;
    let finalResult = [];
    tempques.map(q => {
      // delete q.id;
      // delete q.options;
      // delete q.question_name;
      // delete q.sequence;
      // q.question_id = q.question_id.toString();
      let data = {
        question_id: q.question_id.toString(),
        option_id: q.option_id
      };
      finalResult.push(data);
    });
    try {
      // console.log(finalResult);
      const data = await submitQuizHandler(finalResult, props.token);
      if (data && data.status >= 200 && data.status <= 299) {
        if (data.data.status === 0) {
          toast.error(data.data.message);
          setloading(false);
          navigate('/dashboard');
        } else {
          toast.success(data.data.message);
          setloading(false);
          setResult(data.data.data);
          // navigate('/dashboard');
        }
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      setloading(false);
      navigate('/dashboard');
    }
  };

  return (
    <>
      {result ? (
        <Result result={result} />
      ) : (
        <Card className="mt-4 m-auto">
          {questions.length > 0 && (
            <Card.Body>
              <Row>
                <Col sm={6} className="bg-light">
                  <h2>Question {quenumber + 1}</h2>
                  <Row className="my-5">
                    <Col>
                      <span>{questions[quenumber].question_name}</span>
                    </Col>
                  </Row>
                </Col>
                <Col
                  sm={6}
                  className="border-top pt-3 py-sm-0 border-sm-0 border-md-start bg-light"
                >
                  <h2>Select an option</h2>
                  <Row className="my-5 mx-1">
                    <Col>
                      <Form>
                        <Form.Group as={Col} sm={12}>
                          <div className="row align-items-center">
                            {questions.length &&
                              questions[quenumber].options &&
                              questions[quenumber].options.map(
                                (radio, index) => (
                                  <div
                                    key={index}
                                    className={`m-1 bg-greyhover rounded-3 d-flex align-items-center ${
                                      questions[quenumber].option_id ==
                                        radio.id && 'bg-green'
                                    }`}
                                  >
                                    <Form.Check
                                      type="radio"
                                      id={radio.id}
                                      name={radio.name}
                                      value={radio.id}
                                      checked={
                                        questions[quenumber].option_id ==
                                        radio.id
                                      }
                                      onChange={changeAnswerHandler}
                                    />
                                    <FormCheck.Label
                                      className="p-2 w-100 m-0 cursor-pointer"
                                      htmlFor={radio.id}
                                    >
                                      {radio.name}
                                    </FormCheck.Label>
                                  </div>
                                )
                              )}
                          </div>
                        </Form.Group>

                        <Form.Group className="d-flex mt-6 buttonposition">
                          <Button
                            disabled={quenumber === 0}
                            onClick={prevbtnHandler}
                            className="btn btn-theme"
                            type="submit"
                          >
                            Previous
                          </Button>
                          {questions.length !== quenumber + 1 ? (
                            <>
                              <Button
                                onClick={nextbtnHandler}
                                className="btn px-4 btn-theme mx-3"
                                type="submit"
                                disabled={
                                  questions[quenumber].option_id === undefined
                                }
                              >
                                Next
                              </Button>
                            </>
                          ) : (
                            <Button
                              disabled={
                                questions[quenumber].option_id === undefined
                              }
                              onClick={submitbtnHandler}
                              className="btn px-4 btn-theme mx-3"
                              type="submit"
                            >
                              Submit
                            </Button>
                          )}
                        </Form.Group>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          )}
        </Card>
      )}
      {loading && <Loader loading={loading} />}
    </>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps, null)(Quiz);
