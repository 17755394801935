import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ token, children }) {
  const auth = token;
  return auth ? children : <Navigate to="/login" />;
}
const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps, null)(PrivateRoute);
