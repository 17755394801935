import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Card } from 'react-bootstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  demographicdata,
  updateuserprofileHandler,
  getuserprofile
} from './actionMethods';
import Loader from 'components/loader/Loader';
import { toast } from 'react-toastify';
import { authentication } from '../Login/redux/action';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
const Profile = props => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      name: '',
      nickname: '',
      birth_year: '',
      zodiac_id: '',
      city: '',
      region: '',
      country_id: '',
      birth_sex_id: 0,
      gender_identity_id: 0,
      orientation_id: 0,
      religion_id: 0,
      wealth_id: 0,
      social_class_id: 0,
      occupation_id: 0,
      optimism_id: 0,
      is_healthy: 0,
      relationship_id: 0,
      children_id: 0,
      education_id: 0,
      dead_parent_id: 0,
      languages_id: 0,
      diet_id: 0,
      political_slant_id: 0,
      nationality_id: 0,
      ethnicity_id: 0,
      dominatingHand: 0
    }
  });

  // State
  const [children, setchildren] = useState([]);
  const [country, setcountry] = useState([]);
  const [diet, setdiet] = useState([]);
  const [education, seteducation] = useState([]);
  const [ethnicity, setethnicity] = useState([]);
  const [nationality, setnationality] = useState([]);
  const [genderIndentity, setgenderIdentity] = useState([]);
  const [language, setlanguage] = useState([]);
  const [occupation, setoccupation] = useState([]);
  const [optimism, setoptimism] = useState([]);
  const [orientation, setorientation] = useState([]);
  const [pet, setpet] = useState([]);
  const [politicalSlant, setpoliticalSlant] = useState([]);
  const [relationship, setrelationship] = useState([]);
  const [religion, setreligion] = useState([]);
  const [sex, setsex] = useState([]);
  const [socialClass, setsocialClass] = useState([]);
  const [wealth, setwealth] = useState([]);
  const [zodiac_sign, setzodiac_sign] = useState([]);
  const [dead_parent, setdead_parent] = useState([]);
  // State

  const [ownedpets, setownedpets] = useState([]);
  const [dominatingHand, setdominatingHand] = useState(false);
  const [ishealthy, setishealthy] = useState(false);
  const [fetchuser, setfetchuser] = useState(false);
  const radios = [
    { name: 'Right', value: 1 },
    { name: 'Left', value: 2 }
  ];
  const healthyradios = [
    { name: 'Healthy', value: true },
    { name: 'Not Healthy', value: false }
  ];
  // demographicdata
  useEffect(() => {
    async function fetchDemographicDetails() {
      setloading(true);
      try {
        const data = await demographicdata(props.token);
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.messsage);
          } else {
            const response = data.data.data;
            let pet = [];
            if (response.pet) {
              response.pet.map(p => {
                let petId = { value: p.id, label: p.name };
                pet.push(petId);
              });
            }
            setchildren(response.children);
            setcountry(response.country);
            setdiet(response.diet);
            seteducation(response.education);
            setethnicity(response.ethnicity);
            setgenderIdentity(response.genderIdentity);
            setlanguage(response.language);
            setoccupation(response.occupation);
            setoptimism(response.optimism);
            setorientation(response.orientation);
            setpet(pet);
            setpoliticalSlant(response.politicalSlant);
            setrelationship(response.relationship);
            setreligion(response.religion);
            setsex(response.sex);
            setsocialClass(response.socialClass);
            setwealth(response.wealth);
            setzodiac_sign(response.zodiac_sign);
            setdead_parent(response.deadParents);
            setnationality(response.nationality);
            setfetchuser(true);
          }
        }
      } catch (error) {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.message);
        }
      }
      setloading(false);
    }
    fetchDemographicDetails();
    return () => {
      setchildren([]);
      setcountry([]);
      setdiet([]);
      seteducation([]);
      // setethnicity([]);
      setgenderIdentity([]);
      setlanguage([]);
      setoccupation([]);
      setoptimism([]);
      setorientation([]);
      setpet(pet);
      setpoliticalSlant([]);
      setrelationship([]);
      setreligion([]);
      setsex([]);
      setsocialClass([]);
      setwealth([]);
      setzodiac_sign([]);
      setdead_parent([]);
    };
  }, []);
  // getuserprofile
  useEffect(() => {
    async function fetchProfile() {
      setloading(true);
      try {
        const data = await getuserprofile(props.token);
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.messsage);
          } else {
            const response = data.data.data;
            if (response.pets) {
              // let petArrray = response.pets.split(',');
              let ihavepets = [];
              response.pets.map(p => {
                pet.map(allpet => {
                  if (parseInt(p) === allpet.value) {
                    ihavepets.push(allpet);
                  }
                });
              });
              setownedpets(ihavepets);
            }

            setValue('name', response.name);
            setValue('nickname', response.nickname);
            setValue('birth_year', response.birth_year);
            setValue('zodiac_id', response.zodiac_id);
            setValue('city', response.city);
            setValue('region', response.region);
            setValue('country_id', response.country_id);
            setValue('birth_sex_id', response.birth_sex);
            setValue('gender_identity_id', response.gender_identity_id);
            setValue('orientation_id', response.orientation_id);
            setValue('religion_id', response.religion_id);
            setValue('wealth_id', response.wealth_id);
            setValue('social_class_id', response.social_class_id);
            setValue('occupation_id', response.occupation_id);
            setValue('optimism_id', response.optimism_id);
            setValue('nationality_id', response.nationality_id);
            setValue('ethnicity_id', response.ethnicity_id);
            setValue('optimism_id', response.optimism_id);
            setValue('is_healthy', response.is_healthy);
            setValue('relationship_id', response.relationship);
            setValue('children_id', response.children);
            setValue('education_id', response.education_id);
            setValue('dead_parent_id', response.dead_parents);
            setValue('languages_id', response.languages);
            setValue('diet_id', response.diet);
            setValue('political_slant_id', response.political_slant_id);
            setdominatingHand(response.hand_dominance_id);
            setishealthy(response.is_healthy);
          }
        }
      } catch (error) {
        toast.error('Something went wrong@');
      }
      setfetchuser(false);
      setloading(false);
    }
    if (fetchuser) fetchProfile();
  }, [fetchuser]);

  // updateuserprofile
  const onSubmit = async data => {
    let pet = [];
    // console.log('🚀 ~ file: Profile.js ~ line 190 ~ ownedpets', ownedpets);
    if (ownedpets.length) {
      // console.log('🚀 ~ file: Profile.js ~ line 190 ~ ownedpets', ownedpets);
      ownedpets.map(p => {
        pet.push(p.value);
      });
    } else {
      // console.log('ownedpets.length', ownedpets.length);
    }
    let formData = {
      name: data.name,
      nickname: data.nickname,
      birth_year: data.birth_year,
      zodiac_id: data.zodiac_id,
      city: data.city,
      region: data.region,
      country_id: data.country_id,
      birth_sex_id: data.birth_sex_id,
      gender_identity_id: data.gender_identity_id,
      orientation_id: data.orientation_id,
      religion_id: data.religion_id,
      wealth_id: data.wealth_id,
      social_class_id: data.social_class_id,
      occupation_id: data.occupation_id,
      optimism_id: data.optimism_id,
      is_healthy: ishealthy,
      relationship_id: data.relationship_id,
      children_id: data.children_id,
      education_id: data.education_id,
      dead_parent_id: data.dead_parent_id,
      languages_id: data.languages_id,
      diet_id: data.diet_id,
      pets: pet,
      political_slant_id: data.political_slant_id,
      ethnicity_id: data.ethnicity_id,
      nationality_id: data.nationality_id,
      hand_dominance_id: parseInt(dominatingHand)
    };
    // console.log('formData: ', formData);
    setloading(true);
    try {
      const data = await updateuserprofileHandler(formData, props.token);
      if (data && data.status >= 200 && data.status <= 299) {
        if (data.data.status === 0) {
          toast.error(data.data.message);
        } else {
          // console.log(data);
          toast.success(data.data.message);
          setfetchuser(true);
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
      setloading(false);
    }
    reset();
    navigate('/dashboard');
  };

  return (
    <>
      <Card className="mt-4 w-lg-75 w-md-100 w-sm-75 m-auto">
        <Card.Header>
          <h5 className="mb-0">
            {props.name ? `${props.name}'s` : ''} Profile
          </h5>
        </Card.Header>
        <Card.Body className="bg-grey">
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            {/* NAME , NICKNAME, YEAR */}
            <Row className="g-2 my-4 bg-light p-3">
              <Form.Group as={Col} sm={4}>
                <Form.Label>
                  Name<span className="text-danger">*</span>{' '}
                </Form.Label>
                <Form.Control
                  isInvalid={errors.name}
                  name="name"
                  {...register('name', {
                    required: 'Name is required'
                  })}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name && errors.name.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} sm={4}>
                <Form.Label>
                  Nick Name<span className="text-danger">*</span>{' '}
                </Form.Label>
                <Form.Control
                  isInvalid={errors.nickname}
                  name="nickname"
                  {...register('nickname', {
                    required: 'Nick Name is required'
                  })}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nickname && errors.nickname.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} sm={4}>
                <Form.Label>
                  Birth Year<span className="text-danger">*</span>{' '}
                </Form.Label>
                <Form.Control
                  isInvalid={errors.birth_year}
                  {...register('birth_year', {
                    required: 'Birth Year is required',
                    maxLength: {
                      value: 4,
                      message: 'Birth Year should be 4 digits only'
                    }
                  })}
                  name="birth_year"
                  type="number"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birth_year && errors.birth_year.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {/* Physical */}
            <Row className="g-2 my-4 bg-light p-3">
              <h5>Physical</h5>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Birth sex</Form.Label>
                <Form.Select {...register('birth_sex_id')}>
                  <option value="">Birth sex</option>
                  {sex.length &&
                    sex.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Gender</Form.Label>
                <Form.Select {...register('gender_identity_id')}>
                  <option value="">Gender</option>
                  {genderIndentity.length &&
                    genderIndentity.map(z => {
                      return (
                        <option key={z.id} value={z.id}>
                          {z.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Zodiac Sign</Form.Label>
                <Form.Select
                  isInvalid={errors.zodiac_id}
                  {...register('zodiac_id', {
                    required: 'Zodiac Sign is required'
                  })}
                >
                  <option value="">Zodiac Sign</option>
                  {zodiac_sign.length &&
                    zodiac_sign.map(z => {
                      return (
                        <option key={z.id} value={z.id}>
                          {z.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.zodiac_id && errors.zodiac_id.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Which is your dominating hand?</Form.Label>
                <div className="d-flex m-auto mt-1 align-items-center">
                  {radios.map((radio, index) => (
                    <Form.Check
                      inline
                      key={index}
                      type="radio"
                      name={radio.name}
                      label={radio.name}
                      value={radio.value}
                      checked={dominatingHand == radio.value}
                      onChange={e => setdominatingHand(e.currentTarget.value)}
                    />
                  ))}
                </div>
              </Form.Group>
            </Row>
            {/* GEO */}
            <Row className="g-2 my-4 bg-light p-3">
              <h5>Geographical</h5>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Nationality</Form.Label>
                <Form.Select {...register('nationality_id')}>
                  <option value="">Nationality</option>
                  {nationality.length &&
                    nationality.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>
                  City<span className="text-danger">*</span>{' '}
                </Form.Label>
                <Form.Control
                  isInvalid={errors.city}
                  name="city"
                  {...register('city', {
                    required: 'City is required'
                  })}
                  type="text"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.city && errors.city.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Country</Form.Label>
                <Form.Select
                  isInvalid={errors.country_id}
                  {...register('country_id', {
                    required: 'Country is required'
                  })}
                >
                  <option value="">Countries</option>
                  {country.length &&
                    country.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.country_id && errors.country_id.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Ethinicity</Form.Label>
                <Form.Select {...register('ethnicity_id')}>
                  <option value="">Ethinicity</option>
                  {ethnicity.length &&
                    ethnicity.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Row>
            {/* Professional */}
            <Row className="g-2 my-4 bg-light p-3">
              <h5>Professional</h5>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Wealth</Form.Label>
                <Form.Select
                  isInvalid={errors.wealth_id}
                  {...register('wealth_id')}
                >
                  <option value="">Wealth</option>
                  {wealth.length &&
                    wealth.map(z => {
                      return (
                        <option key={z.id} value={z.id}>
                          {z.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Social Class</Form.Label>
                <Form.Select
                  isInvalid={errors.social_class_id}
                  {...register('social_class_id')}
                >
                  <option value="">Social Class</option>
                  {socialClass.length &&
                    socialClass.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Education</Form.Label>
                <Form.Select {...register('education_id')}>
                  <option value="">Education</option>
                  {education.length &&
                    education.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Employement</Form.Label>
                <Form.Select {...register('occupation_id')}>
                  <option value="">Employement</option>
                  {occupation.length &&
                    occupation.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Language</Form.Label>
                <Form.Select {...register('languages_id')}>
                  <option value="">Language</option>
                  {language.length &&
                    language.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Row>
            {/* Lifestyle */}
            <Row className="g-2 my-4 bg-light p-3">
              <h5>Lifestyle</h5>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Are you healthy?</Form.Label>
                <div className="d-flex m-auto mt-1 align-items-center">
                  {healthyradios.map((radio, index) => (
                    <Form.Check
                      inline
                      key={index}
                      type="radio"
                      name={radio.name}
                      label={radio.name}
                      value={radio.value}
                      checked={ishealthy === radio.value}
                      onChange={() => setishealthy(!ishealthy)}
                    />
                  ))}
                </div>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Relationship</Form.Label>
                <Form.Select {...register('relationship_id')}>
                  <option value="">Relationship</option>
                  {relationship.length &&
                    relationship.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                {/* MULTIPLE SELECTION */}
                <Form.Label>Pet/s</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={pet}
                  placeholder="Pet/s"
                  isMulti
                  classNamePrefix="react-select"
                  value={ownedpets}
                  onChange={value => setownedpets(value)}
                />
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Children</Form.Label>
                <Form.Select {...register('children_id')}>
                  <option value="">Children</option>
                  {children.length &&
                    children.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Diet</Form.Label>
                <Form.Select {...register('diet_id')}>
                  <option value="">Diet</option>
                  {diet.length &&
                    diet.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Row>
            {/* Mentality */}
            <Row className="g-2 my-4 bg-light p-3">
              <h5>Mentality</h5>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Orientation</Form.Label>
                <Form.Select {...register('orientation_id')}>
                  <option value="">Orientation</option>
                  {orientation.length &&
                    orientation.map(z => {
                      return (
                        <option key={z.id} value={z.id}>
                          {z.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Optimism</Form.Label>
                <Form.Select {...register('optimism_id')}>
                  <option value="">Optimism</option>
                  {optimism.length &&
                    optimism.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Religion</Form.Label>
                <Form.Select {...register('religion_id')}>
                  <option value="">Religion</option>
                  {religion.length &&
                    religion.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Dead Parents</Form.Label>
                <Form.Select
                  isInvalid={errors.dead_parent_id}
                  {...register('dead_parent_id')}
                >
                  <option value="">Dead Parents</option>
                  {dead_parent.length &&
                    dead_parent.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={4}>
                <Form.Label>Political Slant</Form.Label>
                <Form.Select {...register('political_slant_id')}>
                  <option value="">Political Slant</option>
                  {politicalSlant.length &&
                    politicalSlant.map(c => {
                      return (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Row>
            {/* UPDATE BUTTON */}
            <Form.Group className="my-3">
              <div className="d-flex flex-wrap flex-column justify-content-between flex-lg-row">
                <div className="text-center flex-lg-start order-2 order-lg-1">
                  <span className="fs--2">
                    Why your profile information is important to us -{' '}
                    <a href="/#your-info">checkout here</a>
                  </span>
                </div>
                <div className="text-center flex-lg-end order-1 order-lg-2">
                  <Button
                    onClick={() => navigate('/dashboard')}
                    className="mx-1 my-3 my-sm-1 my-md-2 my-lg-0 btn-danger"
                    type="reset"
                  >
                    Discard Changes
                  </Button>
                  <Button
                    className="mx-1 my-3 my-sm-1 my-md-2 my-lg-0 btn-theme"
                    type="submit"
                  >
                    Update Profile
                  </Button>
                </div>
              </div>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
      {loading && <Loader loading={loading} />}
    </>
  );
};
const actionCreators = {
  authentication: authentication
};
const mapStateToProps = state => ({
  name:
    state.auth && state.auth.authentication && state.auth.authentication.name,
  token: state.auth.token
});
export default connect(mapStateToProps, actionCreators)(Profile);
