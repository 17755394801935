import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { ReactComponent as Up } from '../../assets/img/down.svg';
import { ReactComponent as Down } from '../../assets/img/up.svg';
const AdviceCollapse = props => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mb-4">
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant="falcon-default"
        className="w-100 text-start shadow-none p-0"
      >
        <div className="d-flex justify-content-between">
          <div>{props.title}</div>
          <div>
            {open ? <Up className="mt-2" /> : <Down className="mt-2" />}
          </div>
        </div>
      </Button>
      <Collapse in={open}>
        <div className="">
          <ul>
            {props.advice.length
              ? props.advice.map((advice, index) => {
                  return (
                    <li key={index} className="text-start m-3">
                      {advice.advice}
                    </li>
                  );
                })
              : ''}
          </ul>
        </div>
      </Collapse>
    </div>
  );
};
export default AdviceCollapse;
