import React, { useState } from 'react';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgImg from 'assets/img/generic/14.png';
import Flex from 'components/common/Flex';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CheckEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnTologinHandler = async e => {
    e.preventDefault();
    navigate('/login');
  };
  const [email] = useState(searchParams.get('email'));
  return (
    <AuthSplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
      <Flex alignItems="center" justifyContent="between">
        <h3>Please check your email!</h3>
      </Flex>
      <span>
        An email has been sent to {email} please click on included link to reset
        your password.
      </span>
      <Form className="mt-2" onSubmit={returnTologinHandler}>
        <Form.Group>
          <Button type="submit" color="primary" className="mt-3 w-100">
            {`< Return to Login`}
          </Button>
        </Form.Group>
      </Form>
    </AuthSplitLayout>
  );
};

export default CheckEmail;
