import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { version } from 'config';
import { Link } from 'react-router-dom';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Privacy from '../../pages/Privacy-policy/Privacy';
// Platform Version 1.10.2.0 - © 2022 - Tadysh Platform - Privacy Policy
// DEMO TEXT

const Footer = props => {
  const [lgShow, setLgShow] = useState(false);
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            Platform Version {props.app_version}
            {' - '}
            <span className="d-none d-sm-inline-block">Tadysh Platform - </span>
            <br className="d-sm-none" /> &copy; {new Date().getFullYear()}
            <Link to="#!" onClick={() => setLgShow(true)}>
              {' '}
              Privacy Policy
            </Link>
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">v{version}</p>
        </Col>
      </Row>
      <Modal
        className="m-auto pl-0"
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="Privacy Policy"
      >
        <Modal.Header>
          <Modal.Title id="Privacy Policy" className="mx-3">
            Privacy Policy
          </Modal.Title>
          <FalconCloseButton onClick={() => setLgShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Privacy />
        </Modal.Body>
      </Modal>
    </footer>
  );
};
export default Footer;
// const mapStateToProps = state => ({
//   app_version: state.auth.authentication
//     ? state.auth.authentication.version
//     : '0'
// });

// export default connect(mapStateToProps, null)(Footer);
