import axios from 'axios';
import { toast } from 'react-toastify';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
const APP_URL = process.env.REACT_APP__API_URL; //dev

const instance = axios.create({
  baseURL: APP_URL,
  timeout: 500000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});
instance.interceptors.response.use(undefined, function (error) {
  var status = error.response.status;
  if (
    (error.response.data.message == 'Unauthenticated.' ||
      error.response.data.message == 'Unauthenticated') &
    (status == 401)
  ) {
    toast.error(error.response.data.message);
    window.location.replace('/login');
  }
  return Promise.reject(error);
});

export default instance;
