import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import MainLayout from './MainLayout';

import ForgotPassword from '../pages/Login/ForgotPassword';
import Login from '../pages/Login/Login';
import SplitRegistration from 'pages/Login/Registration';
import CheckEmail from 'pages/Login/CheckEmail';
import ResetPassword from 'pages/Login/ResetPassword';
import Landing from 'components/pages/landing/Landing';

import Dashboard from 'components/dashboards/default';
import Error404 from 'components/errors/Error404';
import Profile from 'pages/Profile/Profile';
import PrivateRoute from './PrivateRoute';
import Quiz from 'pages/Quiz/Quiz';
import Result from 'pages/Quiz/Result';
// import Privacy from 'pages/Privacy-policy/Privacy';

const AppRouter = () => (
  <>
    <Routes>
      {/* ########## PRIVATE ROUTES ########## */}

      <Route element={<MainLayout />}>
        <Route
          exact={true}
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          exact={true}
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          exact={true}
          path="/quiz"
          element={
            <PrivateRoute>
              <Quiz />
            </PrivateRoute>
          }
        />
        <Route
          exact={true}
          path="/result/:id"
          element={
            <PrivateRoute>
              <Result />
            </PrivateRoute>
          }
        />
      </Route>

      {/* ########## PUBLIC ROUTERS ########## */}
      <Route path="/login" element={<Login />} />
      <Route
        exact="true"
        path="/registration"
        element={<SplitRegistration />}
      />
      <Route
        exact="true"
        path="/forgot-password"
        element={<ForgotPassword />}
      />
      <Route path="/check-email" element={<CheckEmail />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* <Route path="/privacy-policy" element={<Privacy />} /> */}
      <Route exact="true" path="/errors/404" element={<Error404 />} />
      {/* <Header/> */}
      <Route exact="true" path="/" element={<Landing />} />

      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
    <ToastContainer
      icon={false}
      theme="dark"
      position={toast.POSITION.BOTTOM_CENTER}
    />
  </>
);

export default AppRouter;
