import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Loader from 'components/loader/Loader';
import { loginHandler, verifyEmailAPI } from './actionMethods';
import { authentication } from './redux/action';
import { connect } from 'react-redux';

const LoginForm = props => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setloading] = useState(false);
  useEffect(() => {
    return () => {
      setFormData({
        ...formData,
        email: '',
        password: ''
      });
    };
  }, []);

  const navigate = useNavigate();
  const search = useLocation().search;
  useEffect(() => {
    async function verifyEmail(fpToken) {
      try {
        const data = await verifyEmailAPI(fpToken);
        if (data && data.status >= 200 && data.status <= 299) {
          if (data.data.status === 0) {
            toast.error(data.data.message);
          } else {
            toast.success(data.data.message);
          }
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    }
    if (search) {
      const fpToken = new URLSearchParams(search).get('verify-email-token');
      if (fpToken) {
        verifyEmail(fpToken);
      }
    }
  }, [search]);

  // Login Handler
  const userloginHandler = async e => {
    e.preventDefault();
    setloading(true);
    try {
      const data = await loginHandler(formData);
      if (data && data.status >= 200 && data.status <= 299) {
        if (data.data.status === 0) {
          toast.error(data.data.message);
          setloading(false);
        } else {
          let user = {
            name: data.data.data.name,
            email: data.data.data.email
          };
          props.authentication(user, data.data.data.token);
          setloading(false);
          navigate('/dashboard');
        }
      }
    } catch (err) {
      setloading(false);
      toast.error(err.response.data.message);
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Form onSubmit={userloginHandler}>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            placeholder={'Email address'}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            placeholder={'Password'}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto"></Col>

          <Col xs="auto">
            <Link className="fs--1 mb-0" to={`/forgot-password`}>
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.email || !formData.password}
          >
            Log in
          </Button>
        </Form.Group>
      </Form>
      {loading && <Loader loading={loading} />}
    </>
  );
};

const actionCreators = {
  authentication: authentication
};
export default connect(null, actionCreators)(LoginForm);
