import axios from '../../../../helpers/axios';

// ----------------------------------------
// get dashboard
// ----------------------------------------
export const fetchdashboard = token => {
  return axios.get('/dashboard', {
    headers: { Authorization: 'Bearer ' + token }
  });
};
