import axios from '../../../helpers/axios';

// ----------------------------------------
// get quiz handler
// ----------------------------------------
export const getQuizQuestionsHandler = token => {
  return axios.get('/quiz', {
    headers: { Authorization: 'Bearer ' + token }
  });
};

// ----------------------------------------
// Submit quiz handler
// ----------------------------------------
export const submitQuizHandler = (quiz_data, token) => {
  return axios.post(
    '/quiz',
    { quiz_data: quiz_data },
    {
      headers: { Authorization: 'Bearer ' + token }
    }
  );
};

// ----------------------------------------
// get quiz handler
// ----------------------------------------
export const getpastQuizDetails = (uuid, token) => {
  return axios.get(`/past-quiz-result/${uuid}`, {
    headers: { Authorization: 'Bearer ' + token }
  });
};
