import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './layouts/AppRouter';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { history } from 'helpers/history';

const App = () => {
  console.disableYellowBox = true;
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');
    script1.src = '/assets/js/plugins.js';
    script2.src = '/assets/js/theme.js';
    script1.async = true;
    script2.async = true;
    document.body.appendChild(script1);
    document.body.appendChild(script2);
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);
  return (
    <BrowserRouter history={history}>
      <AppRouter />
    </BrowserRouter>
  );
};

export default App;
