import React, { useRef } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, StylesProvider } from '@material-ui/core/styles';
// import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}));
function Backdroploading(props) {
  const ref = useRef(0);
  const classes = useStyles();
  return (
    <div>
      <StylesProvider injectFirst>
        <Backdrop ref={ref} className={classes.backdrop} open={props.loading}>
          <CircularProgress className="loadingStyle" />
        </Backdrop>
      </StylesProvider>
    </div>
  );
}

export default Backdroploading;
