import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FaTiktok, FaTwitter, FaInstagram, FaFacebook } from 'react-icons/fa';
import { ReactComponent as Icon1 } from '../../../assets/img/illustrations/Privacy-Icon.svg';
import { ReactComponent as Icon2 } from '../../../assets/img/illustrations/Data-Analysis-Icon.svg';
import { ReactComponent as Icon3 } from '../../../assets/img/illustrations/Free-To-Use-Icon.svg';
import ScriptTag from 'react-script-tag';
import $ from 'jquery';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { contactUsHandler } from './actionMethods';

const Landing = props => {
  const [loading, setloading] = useState(true);

  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [email, setemail] = useState('');
  const [description, setdescription] = useState('');

  useEffect(() => {
    // turn off the loader once all content is loded on the DOM
    $(document).ready(() => {
      setTimeout(() => {
        $('#load').hide();
        setloading(false);
        if (window.location.hash === '#your-info') {
          document.getElementById('your-info').scrollIntoView();
        }
      }, 2000);
    });
  }, []);
  useEffect(() => {}, [window.location.hash]);

  const contactusAPIHandler = async e => {
    e.preventDefault();
    let formData = {
      first_name: fname,
      last_name: lname,
      email: email,
      message: description
    };
    try {
      const reponse = await contactUsHandler(formData);
      toast.success(reponse.data.message);
      setfname('');
      setlname('');
      setemail('');
      setdescription('');
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <>
      {/* {document.readyState === 'interactive' && ( */}

      {/* )} */}
      <Helmet>
        <meta charset="utf-8" />
        <link rel="stylesheet" href="/assets/css/plugins.css" />
        <link rel="stylesheet" href="/assets/css/style.css" />
        <link rel="stylesheet" href="/assets/css/colors/sky.css" />
        <link
          rel="preload"
          href="/assets/css/fonts/urbanist.css"
          as="style"
          onLoad="this.rel='stylesheet'"
        />
        <link
          rel="preload"
          as="image"
          href="./assets/img/TadyshImage.png"
          imagesrcset="./assets/img/TadyshImage.png 2x"
          imagesizes="50vw"
        />
      </Helmet>
      <ScriptTag
        type="text/javascript"
        isHydrating={true}
        src="/assets/js/theme.js"
      />
      {/* <ScriptTag
        type="text/javascript"
        isHydrating={true}
        src="/assets/js/theme.js"
      /> */}
      {!loading ? (
        <>
          <div className="content-wrapper">
            <header className="wrapper bg-light">
              <nav className="navbar navbar-expand-lg classic transparent navbar-light">
                <div className="container flex-lg-row flex-nowrap align-items-center">
                  <Link to="/">
                    <div className="navbar-brand w-100 py-1">
                      {/* <a href="#"> */}
                      <img
                        className=" w-75 h-100 w-md-100"
                        src="./assets/img/logo.png"
                        srcSet="./assets/img/logo.png 2x"
                        alt=""
                      />
                      {/* </a> */}
                    </div>
                  </Link>{' '}
                  <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
                    <div className="offcanvas-header d-lg-none">
                      <h3 className="text-white fs-30 mb-0">Tadysh</h3>
                      <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
                      <ul className="navbar-nav">
                        <li className="nav-item ">
                          <a className="nav-link" href="#how-it-works">
                            How it works
                          </a>
                        </li>
                        <li className="nav-item ">
                          <a className="nav-link" href="#about-tadysh">
                            About Tadysh
                          </a>
                        </li>
                        <li className="nav-item ">
                          <a className="nav-link" href="#our-goal">
                            Our Aims
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#your-info">
                            Your Info
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#faq">
                            FAQ
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="#contact-us">
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item d-block d-md-none">
                          <a
                            href={!props.token ? '/login' : '/dashboard'}
                            className="btn btn-sm btn-primary rounded-pill"
                          >
                            {!props.token ? 'Login' : 'Dashboard'}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="navbar-other ms-lg-4">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                      <li className="nav-item d-none d-md-block">
                        <a
                          href={!props.token ? '/login' : '/dashboard'}
                          className="btn btn-sm btn-primary rounded-pill"
                        >
                          {!props.token ? 'Login' : 'Dashboard'}
                        </a>
                      </li>
                      <li className="nav-item d-lg-none">
                        <button className="hamburger offcanvas-nav-btn">
                          <span></span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </header>

            <section
              id="section-1"
              name="section-1"
              className="wrapper bg-light"
            >
              <div className="container py-14 py-md-12 text-center">
                <div className="row gx-lg-8 gx-xl-12 gy-10 gy-xl-0 mb-10 align-items-center">
                  <div className="col-lg-7 order-lg-2">
                    <figure>
                      <img
                        className="img-auto"
                        src="../../../assets/img/TadyshImage.png"
                        srcSet="../../../assets/img/TadyshImage.png 2x"
                        alt=""
                      />
                    </figure>
                  </div>

                  <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
                    <h1 className="display-1 fs-54 mb-5 mx-md-n5 mx-lg-0 mt-7">
                      How happy are you this month? <br className="d-md-none" />
                      <span className="rotator-fade text-primary">
                        measure,monitor,maximise
                      </span>
                    </h1>
                    <p className="lead fs-lg mb-7">
                      Answer 20 quick questions to measure your happiness and
                      see your personalised plan to be happier next month.
                    </p>
                    <span>
                      <a
                        href={!props.token ? '/login' : '/dashboard'}
                        className="btn btn-lg btn-primary rounded-pill me-2"
                      >
                        Get started
                      </a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="overflow-hidden">
                <div className="divider text-soft-primary mx-n2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 100"
                  >
                    <path
                      fill="currentColor"
                      d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
                    />
                  </svg>
                </div>
              </div>
            </section>

            <section
              id="how-it-works"
              name="how-it-works"
              className="wrapper bg-gradient-primary"
            >
              <div className="container pb-14 pb-md-10">
                <div className="row text-center">
                  <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <h2 className="fs-16 text-uppercase text-primary mb-3">
                      How it works
                    </h2>
                    <h3 className="display-3 mb-10 px-xxl-10">
                      Step by step, person by person, month by month, Tadysh
                      aims to make the world a little bit happier.
                    </h3>
                  </div>
                </div>

                <div className="row gx-lg-8 gx-xl-12 gy-11 px-xxl-5 text-center d-flex align-items-start">
                  <div className="col-lg-4">
                    <div className="px-md-15 px-lg-3">
                      <figure className="mb-5">
                        <img
                          className="img-fluid"
                          src="./assets/img/illustrations/i24.png"
                          srcSet="./assets/img/illustrations/i24@2x.png 2x"
                          alt="1"
                        />
                      </figure>
                      <h3>Take the test</h3>
                      <p className="mb-2">
                        Answer 20 quick multiple choice questions to see your
                        overall happiness score and scores for 4 spheres of
                        happiness.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="px-md-15 px-lg-3">
                      <figure className="mb-5">
                        <img
                          className="img-fluid"
                          src="./assets/img/illustrations/i19.png"
                          srcSet="./assets/img/illustrations/i19@2x.png 2x"
                          alt="2"
                        />
                      </figure>
                      <h3>Personalised Tips</h3>
                      <p className="mb-2">
                        Follow your guide to develop habits to help you increase
                        your happiness over the coming month.
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="px-md-15 px-lg-3">
                      <figure className="mb-10">
                        <img
                          className="img-fluid"
                          src="./assets/img/illustrations/i18.png"
                          srcSet="./assets/img/illustrations/i18@2x.png 2x"
                          alt="3"
                        />
                      </figure>
                      <h3>Come back next month</h3>
                      <p className="mb-2">
                        Complete the test again.See how your happiness levels
                        have changed and see new tips for the coming month.
                        Track your happiness levels over time in your profile
                        history.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              id="about-tadysh"
              name="about-tadysh"
              className="wrapper bg-gray"
            >
              <div className="container py-10 py-md-12">
                <div className="row mt-6 mt-md-10">
                  <div className="row gx-lg-8 gx-xl-10 mb-lg-19 mb-xl-10 align-items-center">
                    <div className="col-lg-6">
                      <figure>
                        <img
                          src="./assets/img/illustrations/Be-Happier.svg"
                          srcSet="./assets/img/illustrations/Be-Happier.svg 2x"
                          data-cue="fadeIn"
                          alt=""
                        />
                      </figure>
                    </div>
                    <div className="col-lg-6">
                      <h2 className="fs-15 text-uppercase text-muted mb-3">
                        Be Happier!
                      </h2>
                      <h3 className="display-4 mb-5">If not now then when?</h3>
                      <p className="mb-8">
                        A smile is no indication of a person’s happiness. Even
                        asking someone how happy they feel may not garner an
                        accurate answer. Everyone wants to be happier, but how
                        happy are you really, right now? How will you know if
                        you are happier next month, or in 6 months? To navigate
                        to a place, you need to know where you are starting
                        from, otherwise you won’t know which is the best route
                        to take.
                      </p>
                      <p className="mb-8">
                        Whatever your happiness level, Tadysh will take a
                        snapshot of your life and help you get to a happier
                        place; and you’ll know when you get there. If you score
                        50%, work towards a goal of 60%. See how quickly you can
                        change your lifestyle and habits to reach that, then go
                        for 70%. Most of the guidance is not going to cost you
                        anything other than effort and commitment. Your
                        happiness is in your hands and you can be happier.
                        Tadysh is your free and anonymous tool to achieve that.{' '}
                      </p>
                    </div>
                  </div>
                  <div
                    id="our-goal"
                    className="row gx-lg-8 gx-xl-10 mb-lg-19 mb-xl-10 align-items-center"
                  >
                    <div className="col-lg-6">
                      <h2 className="fs-15 text-uppercase text-muted mb-3">
                        Our Goal
                      </h2>
                      <h3 className="display-4 mb-5">
                        Happier people, happier work places, happier communities
                      </h3>
                      <p className="mb-8">
                        For at least one person to choose a happier life over
                        suicide. For the familiy and friends of at least one
                        person to contiue their lives oblivious to how close
                        they came to a life-changing tragedy. To inspire at
                        least one person to hold on for another month to see if
                        they can be happier. Our goal is one person, our dream
                        is for everyone to be happier than they are right now.
                        If you would like to support the Tadysh project to add
                        languages and increase our reach, please visit our
                        GoFundMe page{' '}
                      </p>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-primary rounded-pill mb-0 text-nowrap"
                        href="https://gofund.me/f9e919e6"
                      >
                        GoFundMe
                      </a>
                    </div>
                    <div className="col-lg-6">
                      <figure>
                        <img
                          src="./assets/img/illustrations/Goal-Vecter.svg"
                          srcSet="./assets/img/illustrations/Goal-Vecter.svg 2x"
                          data-cue="fadeIn"
                          alt="ourgoal"
                        />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/* <section
              id="section-7"
              name="section-7"
              className="wrapper bg-light angled upper-end"
            >
              <div className="container py-10 py-md-12 text-center">
                <div className="col-xl-10 mx-auto">
                  <div className="row">
                    <div
                      className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400"
                      data-image-src="./assets/img/photos/bg3.jpg"
                    >
                      <div className="card-body p-6 p-md-11 d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                        <h3 className="display-6 mb-6 mb-lg-0 pe-lg-10 pe-xl-5 pe-xxl-18 text-white">
                          If you feel like you may benefit from additional
                          support, please contact the Samaritans on 116 123.
                        </h3>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.samaritans.org/"
                          className="btn btn-white rounded-pill mb-0 text-nowrap"
                        >
                          Samaritans Website
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section
              id="your-info"
              name="your-info"
              className="wrapper bg-gray"
            >
              <div className="container py-10 py-md-12">
                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-16 align-items-center">
                  <div className="col-lg-7">
                    <figure>
                      <img
                        className="w-auto"
                        src="./assets/img/illustrations/i3.png"
                        srcSet="./assets/img/illustrations/i3@2x.png 2x"
                        alt=""
                      />
                    </figure>
                  </div>

                  <div className="col-lg-5">
                    <h2 className="fs-15 text-uppercase text-line text-primary mb-3">
                      Information about you
                    </h2>
                    <h3 className="display-5 mb-7 pe-xxl-5">
                      Why your profile data is important?
                    </h3>
                    <div className="d-flex flex-row mb-4">
                      <div>
                        <Icon1 className="mt-1 me-4" width={50} height={50} />
                      </div>
                      <div>
                        <h4 className="mb-1">Total Privacy</h4>
                        <p className="mb-1">
                          Your quiz answers and profile information are
                          completely anonymous. No person, company or other app
                          will be able to see your answers, your name or your
                          email address.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-4">
                      <div>
                        <Icon2 className="mt-1 me-4" width={50} height={50} />
                      </div>
                      <div>
                        <h4 className="mb-1">Data Analysis</h4>
                        <p className="mb-1">
                          The information you volunteer in your profile allows
                          us to draw conclusions about significant differences
                          in happiness between different groups in society, such
                          as age, gender, location etc. You can leave all or any
                          of the fields blank, just fill out what you are
                          comfortable with.
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div>
                        <Icon3 className="mt-1 me-4" width={50} height={50} />
                      </div>
                      <div>
                        <h4 className="mb-1">Free to Use</h4>
                        <p className="mb-0">
                          The quiz, quiz history, results and tips are free to
                          use, and will always be freely available. Our income
                          comes from the research and generalisations we can
                          make from the data. You can support Tadysh via{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://gofund.me/f9e919e6"
                          >
                            GoFundMe
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section name="faq" id="faq" className="wrapper bg-light">
              <div className="container py-10 py-md-12">
                <div className="row gx-lg-8 gx-xl-12 gy-10">
                  <div className="col-lg-6 mb-0">
                    <h2 className="fs-16 text-uppercase text-primary mb-4">
                      FAQ
                    </h2>
                    <h3 className="display-3 mb-4">
                      Do you have a question not covered in the FAQs?
                    </h3>
                    <p className="mb-6">
                      Drop us a line for for a quick answer.{' '}
                      <a href="#contact-us">contact us</a>
                    </p>
                    <div
                      className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-400"
                      data-image-src="./assets/img/photos/bg3.jpg"
                    >
                      <div className="card-body d-lg-flex flex-row align-items-lg-center justify-content-md-between text-center text-lg-start">
                        <h3 className="fs--1 display-6 mb-6 mb-lg-0 pe-lg-10 pe-xl-5 pe-xxl-18 text-white">
                          If you feel like you may benefit from additional
                          support, please contact the Samaritans on 116 123.
                        </h3>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.samaritans.org/"
                          className="fs--1 btn btn-white rounded-pill mb-0 text-nowrap"
                        >
                          Samaritans Website
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div id="accordion-3" className="accordion-wrapper">
                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-1">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-1"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-1"
                          >
                            Is there any cost to use and benefit from Tadysh?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-1"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-1"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              No, the service is totally free to measure,
                              monitor and maximise your happiness.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-2">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-2"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-2"
                          >
                            How accurate is my score?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-2"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-2"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              It is a fairly accurate indicator of general
                              happiness based on psychological theory. Factors
                              affecting your personal happiness cannot all be
                              taken into account, so the score is only your
                              starting point. You may be happier than someone
                              with a higher score than you, depending on what
                              impacts your personal happiness but what is
                              important is where and how your score moves over
                              time. If your score is increasing over time, you
                              are likely to be happier.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-4">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-4"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-4"
                          >
                            If I follow the advice, will I be happier?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-4"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-4"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              Undoubtedly. By improving your habits and
                              lifestyle, your life will be happier. Other
                              influences may affect your happiness, but you will
                              be happier.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-5">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-5"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-5"
                          >
                            Is my private information shared with anyone?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-5"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-5"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              No, none of your personal information is shared
                              with anyone.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-6">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-6"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-6"
                          >
                            How often should I complete the Tadysh quiz?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-6"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-6"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              Every month. You will be notified when it is time
                              to complete the quiz. For the best results, you
                              should try to stick completing to the quiz
                              monthly.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-7">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-7"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-7"
                          >
                            Do I need to register to complete the quiz?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-7"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-7"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              Yes, you will need a user profile to complete the
                              quiz.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="card accordion-item shadow-lg">
                        <div className="card-header" id="accordion-heading-3-8">
                          <button
                            className="collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target="#accordion-collapse-3-8"
                            aria-expanded="false"
                            aria-controls="accordion-collapse-3-8"
                          >
                            Can you produce a personal version of Tadysh,
                            instead of a general happiness one?
                          </button>
                        </div>

                        <div
                          id="accordion-collapse-3-8"
                          className="collapse"
                          aria-labelledby="accordion-heading-3-8"
                          data-bs-target="#accordion-3"
                        >
                          <div className="card-body">
                            <p>
                              Yes, but this is involves a lot of one-on-one
                              contact to establish your personal influences, how
                              important you rate these and how you measure each
                              factor. The processing of the results and coaching
                              are also done individually. The cost of this
                              service £2,500+vat per month for the first year
                              and £1,000+vat per month thereafter. Contact us
                              for more information
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              name="contact-us"
              id="contact-us"
              className="wrapper bg-gray angled upper-end"
            >
              <div className="container py-10 py-md-12">
                <h2 className="fs-16 text-uppercase text-primary mb-4">
                  Contact us
                </h2>
                <div className="row gy-10 gx-lg-8 gx-xl-12 mb-16 align-items-center">
                  <div className="col-lg-7 position-relative">
                    <div
                      className="shape bg-dot primary rellax w-18 h-18"
                      data-rellax-speed="1"
                      style={{ top: 0, left: '-1.4rem', zIndex: 0 }}
                    ></div>
                    <div className="row gx-md-5 gy-5">
                      <div className="col-md-6">
                        <figure className="rounded mt-md-10 position-relative">
                          <img
                            src="./assets/img/photos/g5.jpg"
                            srcSet="./assets/img/photos/g5@2x.jpg 2x"
                            alt=""
                          />
                        </figure>
                      </div>

                      <div className="col-md-6">
                        <div className="row gx-md-5 gy-5">
                          <div className="col-md-12 order-md-2">
                            <figure className="rounded">
                              <img
                                src="./assets/img/photos/g6.jpg"
                                srcSet="./assets/img/photos/g6@2x.jpg 2x"
                                alt=""
                              />
                            </figure>
                          </div>

                          <div className="col-md-10">
                            <div className="card bg-pale-primary text-center counter-wrapper">
                              <div className="card-body py-11">
                                <h3 className="counter text-nowrap">5000+</h3>
                                <p className="mb-0">Satisfied Customers</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <h2 className="display-4 mb-8">
                      Want to know more? - We ask you questions so feel free to
                      ask us anything.
                    </h2>
                    <div className="d-flex flex-row">
                      <div>
                        <div className="icon text-primary fs-28 me-6 mt-n1">
                          {' '}
                          <i className="uil uil-location-pin-alt"></i>{' '}
                        </div>
                      </div>
                      <div>
                        <h5 className="mb-1">Address</h5>
                        <address>
                          Posh Solutions Ltd
                          <br />
                          Enterprise Centre <br />
                          David Lane, <br />
                          Nottingham,
                          <br />
                          NG6 0JU
                          <br />
                          United Kingdom
                        </address>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div>
                        <div className="icon text-primary fs-28 me-6 mt-n1">
                          {' '}
                          <i className="uil uil-phone-volume"></i>{' '}
                        </div>
                      </div>
                      <div>
                        <h5 className="mb-1">Phone</h5>
                        <p>0161 909 6899</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div>
                        <div className="icon text-primary fs-28 me-6 mt-n1">
                          {' '}
                          <i className="uil uil-envelope"></i>{' '}
                        </div>
                      </div>
                      <div>
                        <h5 className="mb-1">E-mail</h5>
                        <p className="mb-0">
                          <a
                            href="mailto:happy@tadysh.com"
                            className="link-body"
                          >
                            happy@tadysh.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                    <h2 className="display-4 mb-3 text-center">
                      Drop Us a Line
                    </h2>
                    <p className="lead text-center mb-10">
                      Reach out to us from our contact form and we will get back
                      to you shortly.
                    </p>
                    <form className="contact-form needs-validation" noValidate>
                      <div className="messages"></div>
                      <div className="row gx-4">
                        <div className="col-md-6">
                          <div className="form-floating mb-4">
                            <input
                              id="form_name"
                              type="text"
                              name="name"
                              value={fname}
                              onChange={e => setfname(e.target.value)}
                              className="form-control"
                              placeholder="Jane"
                              required
                            />
                            <label htmlFor="form_name">First Name *</label>
                            <div className="valid-feedback"> Looks good! </div>
                            <div className="invalid-feedback">
                              {' '}
                              Please enter your first name.{' '}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-floating mb-4">
                            <input
                              id="form_lastname"
                              type="text"
                              name="surname"
                              value={lname}
                              onChange={e => setlname(e.target.value)}
                              className="form-control"
                              placeholder="Doe"
                              required
                            />
                            <label htmlFor="form_lastname">Last Name *</label>
                            <div className="valid-feedback"> Looks good! </div>
                            <div className="invalid-feedback">
                              {' '}
                              Please enter your last name.{' '}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-floating mb-4">
                            <input
                              value={email}
                              onChange={e => setemail(e.target.value)}
                              id="form_email"
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="jane.doe@example.com"
                              required
                            />
                            <label htmlFor="form_email">Email *</label>
                            <div className="valid-feedback"> Looks good! </div>
                            <div className="invalid-feedback">
                              {' '}
                              Please provide a valid email address.{' '}
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-floating mb-4">
                            <textarea
                              id="form_message"
                              name="message"
                              value={description}
                              onChange={e => setdescription(e.target.value)}
                              className="form-control"
                              placeholder="Your message"
                              style={{ height: '150px' }}
                              required
                            ></textarea>
                            <label htmlFor="form_message">Message *</label>
                            <div className="valid-feedback"> Looks good! </div>
                            <div className="invalid-feedback">
                              {' '}
                              Please enter your messsage.{' '}
                            </div>
                          </div>
                        </div>

                        <div className="col-12 text-center">
                          <button
                            disabled={
                              fname.current ||
                              lname.current ||
                              email.current ||
                              description.current
                            }
                            type="submit"
                            onClick={contactusAPIHandler}
                            className="btn btn-primary rounded-pill btn-send mb-3"
                          >
                            Send message
                          </button>
                          <p className="text-muted">
                            <strong>*</strong> These fields are required.
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>

                  <nav className="mb-2 nav social justify-content-center">
                    <a
                      rel="noreferrer"
                      href="https://www.instagram.com/tadyshhappy/"
                      target="_blank"
                    >
                      <FaInstagram fontSize={'16px'} />
                    </a>
                    <a
                      rel="noreferrer"
                      href="https://www.tiktok.com/@tadysh1"
                      target="_blank"
                    >
                      <FaTiktok fontSize={'16px'} />
                    </a>

                    <a
                      rel="noreferrer"
                      href="https://www.facebook.com/profile.php?id=100072410654803"
                      target="_blank"
                    >
                      <FaFacebook fontSize={'16px'} />
                    </a>
                    <a
                      rel="noreferrer"
                      href="https://twitter.com/tadyshhappy"
                      target="_blank"
                    >
                      <FaTwitter fontSize={'16px'} />
                    </a>
                  </nav>
                  <p className="mb-6 text-center">
                    Follow our social media channels for great tips, guidance
                    and news about happiness.
                  </p>
                </div>
              </div>
              <div className="overflow-hidden">
                <div className="divider text-navy mx-n2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1440 100"
                  >
                    <path
                      fill="currentColor"
                      d="M1260,1.65c-60-5.07-119.82,2.47-179.83,10.13s-120,11.48-180,9.57-120-7.66-180-6.42c-60,1.63-120,11.21-180,16a1129.52,1129.52,0,0,1-180,0c-60-4.78-120-14.36-180-19.14S60,7,30,7H0v93H1440V30.89C1380.07,23.2,1319.93,6.15,1260,1.65Z"
                    />
                  </svg>
                </div>
              </div>
            </section>
          </div>
          <footer className="bg-navy text-inverse">
            <div className="container pt-12 pt-lg-6 pb-13 pb-md-15">
              <div className="d-lg-flex flex-row align-items-lg-center">
                <h3 className="display-3 mb-6 mb-lg-0 pe-lg-20 pe-xl-22 pe-xxl-25 text-white">
                  Are you ready to measure your happiness? Take the test now.
                </h3>
                <a
                  rel="noreferrer"
                  href={!props.token ? '/login' : '/dashboard'}
                  className="btn btn-primary rounded-pill mb-0 text-nowrap"
                >
                  Get started
                </a>
              </div>

              <hr className="mt-11 mb-12" />
              <div className="row gy-6 gy-lg-0">
                <div className="col-md-4 col-lg-4">
                  <div className="widget">
                    <img
                      className="mb-4 w-75 h-100 w-md-100"
                      src="./assets/img/Logo_.png"
                      srcSet="./assets/img/Logo_.png 2x"
                      alt=""
                    />
                    <p className="mb-4">
                      © {new Date().getFullYear()} Tadysh.{' '}
                      <br className="d-none d-lg-block" />
                      All rights reserved.
                    </p>
                    <nav className="nav social social-white">
                      <a
                        rel="noreferrer"
                        href="https://www.instagram.com/tadyshhappy/"
                        target="_blank"
                      >
                        <FaInstagram fontSize={'16px'} />
                      </a>
                      <a
                        rel="noreferrer"
                        href="https://www.tiktok.com/@tadysh1"
                        target="_blank"
                      >
                        <FaTiktok fontSize={'16px'} />
                      </a>

                      <a
                        rel="noreferrer"
                        href="https://www.facebook.com/profile.php?id=100072410654803"
                        target="_blank"
                      >
                        <FaFacebook fontSize={'16px'} />
                      </a>
                      <a
                        rel="noreferrer"
                        href="https://twitter.com/tadyshhappy"
                        target="_blank"
                      >
                        <FaTwitter fontSize={'16px'} />
                      </a>
                    </nav>
                  </div>
                </div>

                <div className="col-md-4 col-lg-4">
                  <div className="widget">
                    <h4 className="widget-title text-white mb-3">
                      Get in Touch
                    </h4>
                    <address className="pe-xl-15 pe-xxl-17">
                      Posh Solutions Ltd
                      <br /> Enterprise Centre
                      <br /> David Lane,
                      <br />
                      Nottingham,
                      <br /> NG6 0JU <br />
                      United Kingdom
                    </address>
                    <a href="mailto:happy@tadysh.com">happy@tadysh.com</a>
                    <br /> 0161 909 6899
                  </div>
                </div>

                <div className="col-md-4 col-lg-4">
                  <div className="widget">
                    <h4 className="widget-title text-white mb-3">Learn More</h4>
                    <ul className="list-unstyled  mb-0">
                      <li>
                        <a href="#how-it-works">How it works</a>
                      </li>
                      <li>
                        <a href="#about-tadysh">About Tadysh</a>
                      </li>
                      <li>
                        <a href="#our-goal">Our Aims</a>
                      </li>
                      <li>
                        <a href="#your-info">Your info</a>
                      </li>
                      <li>
                        <a href="#faq">FAQ</a>
                      </li>
                      <li>
                        <a href="#contact-us">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          <div className="progress-wrap">
            <svg
              className="progress-circle svg-content"
              width="100%"
              height="100%"
              viewBox="-1 -1 102 102"
            >
              <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
          </div>
        </>
      ) : (
        <div id="load" className="page-loader"></div>
      )}
    </>
  );
};
const mapStateToProps = state => ({
  token: state.auth.token
});
export default connect(mapStateToProps, null)(Landing);
