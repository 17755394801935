import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { resetpasswordHandlerAPI } from './actionMethods';
import Loader from 'components/loader/Loader';
import AuthSplitLayout from 'layouts/AuthSplitLayout';
import Flex from 'components/common/Flex';
import bgImg from 'assets/img/generic/14.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

const ResetPassword = () => {
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    fpToken: ''
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const search = useLocation().search;
  const fpToken = new URLSearchParams(search).get('forgot_token');
  const email = new URLSearchParams(search).get('email');

  // const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // const vurl = `${name}&signature=${sign}`;
  //   // verify email handler
  //   async function verifyEmail() {
  //     setLoading(true);
  //     try {
  //       const data = await verifyEmailAPI(vurl, props.token);
  //       if (data && data.status >= 200 && data.status <= 299) {
  //         if (data.data.status === 0) {
  //           toast.error(data.data.message);
  //           navigate('/login');
  //           setStatus(0);
  //         } else {
  //           toast.success(data.data.message);
  //           setStatus(1);
  //         }
  //       }
  //     } catch (err) {
  //       toast.error(err.response.data.message);
  //     }
  //     return true;
  //   }
  //   verifyEmail();
  //   setLoading(false);
  // }, []);

  // reset password handler
  const resetPasswordHandler = async pwd => {
    setFormData({
      ...formData,
      password: pwd.password,
      confirmPassword: pwd.confirmPassword
    });
    setLoading(true);

    try {
      const response = await resetpasswordHandlerAPI(pwd, fpToken, email);
      if (response && response.status >= 200 && response.status <= 299) {
        if (response.data.status === 0) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setLoading(false);
          navigate('/login');
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
    reset({
      data: { password: '', confirmPassword: '' }
    });
  };

  return (
    <>
      <AuthSplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
        <Flex alignItems="center" justifyContent="between">
          <h3>Reset Password</h3>
        </Flex>
        <Form noValidate onSubmit={handleSubmit(resetPasswordHandler)}>
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              name="password"
              type="password"
              autoComplete="off"
              isInvalid={errors.password}
              {...register('password', {
                required: 'Password is required',
                minLength: {
                  value: 8,
                  message: 'The password must be at least 8 characters.'
                }
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password && errors.password.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              autoComplete="off"
              isInvalid={!!errors.confirmPassword}
              {...register('confirmPassword', {
                required: 'confirm password is required',
                validate: value =>
                  value === watch('password') || 'The password do not match'
              })}
            />

            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword && errors.confirmPassword.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button type="submit" color="primary" className="mt-3 w-100">
              Set Password
            </Button>
          </Form.Group>
        </Form>
      </AuthSplitLayout>
      {loading && <Loader loading={loading} />}
    </>
  );
};
const mapStatetoProps = state => ({
  token: state.auth.token
});
export default connect(mapStatetoProps, null)(ResetPassword);
